import React, { Component } from 'react';
class SalesMessage extends Component {
    render() {
        return (
            <div className="message-section">
                <h4 style={{ backgroundColor: '#17a2b8', color: "#FFFFFF" }}>Message</h4>
            </div>
        );
    };
}
export default SalesMessage;
